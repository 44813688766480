import Layout from '../components/Layout';
import React, { FC } from 'react';

const Index: FC = () => {
  return (
    <Layout>
      <h2>Datenschutzerklärung</h2>
      <p>
        Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der
        EU-Datenschutzgrundverordnung (DSGVO), ist:
      </p>
      <br />
      <p>
        Nicola Hahne
        <br />
        Fabrikstrasse 2
        <br />
        4123 Allschwil
      </p>
      <p>
        Telefon: 076 416 03 45
        <br />
        E-Mail: nic-hahne@hotmail.com
        <br />
        WebSite: http://nichahne-photography.ch/
      </p>
      <h2>Allgemeiner Hinweis</h2>
      <p>
        Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und den datenschutzrechtlichen
        Bestimmungen des Bundes (Datenschutzgesetz, DSG) hat jede Person Anspruch auf Schutz ihrer
        Privatsphäre sowie auf Schutz vor Missbrauch ihrer persönlichen Daten. Die Betreiber dieser
        Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
        personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
        Datenschutzvorschriften sowie dieser Datenschutzerklärung.
      </p>
      <p>
        In Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die Datenbanken so gut wie
        möglich vor fremden Zugriffen, Verlusten, Missbrauch oder vor Fälschung zu schützen.
      </p>
      <p>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per
        E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
        durch Dritte ist nicht möglich.
      </p>
      <p>
        Durch die Nutzung dieser Website erklären Sie sich mit der Erhebung, Verarbeitung und
        Nutzung von Daten gemäss der nachfolgenden Beschreibung einverstanden. Diese Website kann
        grundsätzlich ohne Registrierung besucht werden. Dabei werden Daten wie beispielsweise
        aufgerufene Seiten bzw. Namen der abgerufenen Datei, Datum und Uhrzeit zu statistischen
        Zwecken auf dem Server gespeichert, ohne dass diese Daten unmittelbar auf Ihre Person
        bezogen werden. Personenbezogene Daten, insbesondere Name, Adresse oder E-Mail-Adresse
        werden soweit möglich auf freiwilliger Basis erhoben. Ohne Ihre Einwilligung erfolgt keine
        Weitergabe der Daten an Dritte.
      </p>
      <p>&nbsp;</p>
      <h2>Bearbeitung von Personendaten</h2>
      <p>
        Personendaten sind alle Angaben, die sich auf eine bestimmte oder bestimmbare Person
        beziehen. Eine betroffene Person ist eine Person, über die Personendaten bearbeitet werden.
        Bearbeiten umfasst jeden Umgang mit Personendaten, unabhängig von den angewandten Mitteln
        und Verfahren, insbesondere das Aufbewahren, Bekanntgeben, Beschaffen, Löschen, Speichern,
        Verändern, Vernichten und Verwenden von Personendaten.
      </p>
      <p>
        Wir bearbeiten Personendaten im Einklang mit dem schweizerischen Datenschutzrecht. Im
        übrigen bearbeiten wir – soweit und sofern die EU-DSGVO anwendbar ist – Personendaten gemäss
        folgenden Rechtsgrundlagen im Zusammenhang mit Art. 6 Abs. 1 DSGVO
      </p>
      <ul>
        <li>lit. a) Bearbeitung von Personendaten mit Einwilligung der betroffenen Person.</li>
        <li>
          lit. b) Bearbeitung von Personendaten zur Erfüllung eines Vertrages mit der betroffenen
          Person sowie zur Durchführung entsprechender vorvertraglicher Massnahmen.
        </li>
        <li>
          lit. c) Bearbeitung von Personendaten zur Erfüllung einer rechtlichen Verpflichtung, der
          wir gemäss allenfalls anwendbarem Recht der EU oder gemäss allenfalls anwendbarem Recht
          eines Landes, in dem die DSGVO ganz oder teilweise anwendbar ist, unterliegen.
        </li>
        <li>
          lit. d) Bearbeitung von Personendaten um lebenswichtige Interessen der betroffenen Person
          oder einer anderen natürlichen Person zu schützen.
        </li>
        <li>
          lit. f) Bearbeitung von Personendaten um die berechtigten Interessen von uns oder von
          Dritten zu wahren, sofern nicht die Grundfreiheiten und Grundrechte sowie Interessen der
          betroffenen Person überwiegen. Berechtigte Interessen sind insbesondere
          betriebswirtschaftliches Interesse, unsere Website bereitstellen zu können, die
          Informationssicherheit, die Durchsetzung von eigenen rechtlichen Ansprüchen und die
          Einhaltung von schweizerischem Recht.
        </li>
      </ul>
      <p>
        Wir bearbeiten Personendaten für jene Dauer, die für den jeweiligen Zweck oder die
        jeweiligen Zwecke erforderlich ist. Bei länger dauernden Aufbewahrungspflichten aufgrund von
        gesetzlichen und sonstigen Pflichten, denen wir unterliegen, schränken wir die Bearbeitung
        entsprechend ein.
      </p>
      <p>&nbsp;</p>
      <h2>Datenschutzerklärung für Cookies</h2>
      <p>
        Diese Website verwendet Cookies. Das sind kleine Textdateien, die es möglich machen, auf dem
        Endgerät des Nutzers spezifische, auf den Nutzer bezogene Informationen zu speichern,
        während er die Website nutzt. Cookies ermöglichen es, insbesondere Nutzungshäufigkeit und
        Nutzeranzahl der Seiten zu ermitteln, Verhaltensweisen der Seitennutzung zu analysieren,
        aber auch unser Angebot kundenfreundlicher zu gestalten. Cookies bleiben über das Ende einer
        Browser-Sitzung gespeichert und können bei einem erneuten Seitenbesuch wieder aufgerufen
        werden. Wenn Sie das nicht wünschen, sollten Sie Ihren Internetbrowser so einstellen, dass
        er die Annahme von Cookies verweigert.
      </p>
      <p>
        Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten
        Cookies kann bei einer Vielzahl der Dienste, vor allem im Fall des Trackings, über die
        US-amerikanische Seite{' '}
        <a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener noreferrer">
          http://www.aboutads.info/choices/
        </a>{' '}
        oder die EU-Seite{' '}
        <a href="http://www.youronlinechoices.com/" target="_blank" rel="noopener noreferrer">
          http://www.youronlinechoices.com/
        </a>{' '}
        erklärt werden. Des Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in
        den Einstellungen des Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls
        nicht alle Funktionen dieses Onlineangebotes genutzt werden können.
      </p>
      <p>&nbsp;</p>
      <h2>Datenschutzerklärung für SSL-/TLS-Verschlüsselung</h2>
      <p>
        Diese Website nutzt aus Gründen der Sicherheit und zum Schutz der übertragung vertraulicher
        Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als Seitenbetreiber senden, eine
        SSL-/TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die
        Adresszeile des Browsers von &quot;http://&quot; auf &quot;https://&quot; wechselt und an
        dem Schloss-Symbol in Ihrer Browserzeile.
      </p>
      <p>
        Wenn die SSL bzw. TLS Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
        übermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <p>&nbsp;</p>
      <h2>Datenübertragungssicherheit (ohne SSL)</h2>
      <p>
        Bitte beachten Sie, dass Daten, die über ein offenes Netz wie das Internet oder einen
        E-Mail-Dienst ohne SSL-Verschlüsselung übermittelt werden, für jedermann einsehbar sind.
        Eine unverschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers
        &quot;http://&quot; anzeigt und kein Schloss-Symbol in Ihrer Browserzeile angezeigt wird.
        Informationen die über das Internet übertragen werden und online empfangene Inhalte, können
        unter Umständen über Netze von Drittanbietern übermittelt werden. Wir können die
        Vertraulichkeit von Mitteilungen oder Unterlagen, die über solche offenen Netze oder Netze
        von Drittanbietern übermittelt werden, nicht garantieren.
      </p>
      <p>
        Wenn Sie über ein offenes Netz oder Netze von Drittanbietern personenbezogene Informationen
        bekannt geben, sollten Sie sich der Tatsache bewusst sein, dass Ihre Daten verloren gehen
        oder Dritte potenziell auf diese Informationen zugreifen und folglich die Daten ohne Ihre
        Zustimmung sammeln und nutzen können. Zwar werden in vielen Fällen die einzelnen Datenpakete
        verschlüsselt übermittelt, nicht aber die Namen des Absenders und des Empfängers. Selbst
        wenn der Absender und der Empfänger im gleichen Land wohnen, erfolgt die Datenübermittlung
        über solche Netze häufig und ohne Kontrollen auch über Drittstaaten, d.h. auch über Länder,
        die nicht das gleiche Datenschutzniveau bieten wie Ihr Domizilland. Wir übernehmen für die
        Sicherheit Ihrer Daten während der übermittlung über das Internet keine Verantwortung und
        lehnen jede Haftung für mittelbare und unmittelbare Verluste ab. Wir bitten Sie, andere
        Kommunikationsmittel zu benutzen, sollten Sie dies aus Gründen der Sicherheit für notwendig
        oder vernünftig erachten.
      </p>
      <p>
        Trotz umfangreicher technischer und organisatorischer Sicherungsvorkehrungen, können
        möglicherweise Daten verloren gehen oder von Unbefugten abgefangen und/oder manipuliert
        werden. Wir treffen soweit möglich geeignete technische und organisatorische
        Sicherheitsmassnahmen, um dies innerhalb unseres Systems zu verhindern. Ihr Computer
        befindet sich indessen ausserhalb des von uns kontrollierbaren Sicherheitsbereichs. Es
        obliegt Ihnen als Benutzer, sich über die erforderlichen Sicherheitsvorkehrungen zu
        informieren und diesbezüglich geeignete Massnahmen zu treffen. Als Website-Betreiber haften
        wir keinesfalls für Schäden, die Ihnen aus Datenverlust oder -manipulation entstehen können.
      </p>
      <p>
        Daten welche Sie in Online-Formularen angeben, können zwecks Auftragsabwicklung an
        beauftragte Dritte weitergegeben und von diesen eingesehen und allenfalls bearbeitet werden.
      </p>
      <p>&nbsp;</p>
      <h2>Datenschutzerklärung für Server-Log-Files</h2>
      <p>
        Der Provider dieser Website erhebt und speichert automatisch Informationen in so genannten
        Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:
      </p>
      <ul>
        <li>Browsertyp und Browserversion</li>
        <li>verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
      </ul>
      <p>
        Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit
        anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich
        zuprüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
      </p>
      <p>&nbsp;</p>
      <h2>Datenschutzerklärung für Kontaktformular</h2>
      <p>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
        Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der
        Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
        nicht ohne Ihre Einwilligung weiter.
      </p>
      <p>&nbsp;</p>
      <h2>Rechte betroffener Personen</h2>
      <p>
        <strong>Recht auf Bestätigung</strong>
      </p>
      <p>
        Jede betroffene Person hat das Recht, vom Betreiber der Website eine Bestätigung darüber zu
        verlangen, ob betroffene Personen betreffende, personenbezogene Daten verarbeitet werden.
        Möchten Sie dieses Bestätigungsrecht in Anspruch nehmen, können Sie sich hierzu jederzeit an
        den Datenschutzbeauftragten wenden.
      </p>
      <br />
      <p>
        <strong>Recht auf Auskunft</strong>
      </p>
      <p>
        Jede von der Verarbeitung betroffene Person mit personenbezogenen Daten hat das Recht,
        jederzeit vom Betreiber dieser Website unentgeltliche Auskunft über die zu seiner Person
        gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten. Ferner
        kann gegebenenfalls über folgende Informationen Auskunft gegeben werden:
      </p>
      <ul>
        <li>die Verarbeitungszwecke</li>
        <li>die Kategorien personenbezogener Daten, die verarbeitet werden</li>
        <li>
          die Empfänger, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder
          noch offengelegt werden
        </li>
        <li>
          falls möglich, die geplante Dauer, für die die personenbezogenen Daten gespeichert werden,
          oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer
        </li>
        <li>
          das Bestehen eines Rechts auf Berichtigung oder Löschung der sie betreffenden
          personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen
          oder eines Widerspruchsrechts gegen diese Verarbeitung
        </li>
        <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde</li>
        <li>
          wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden: Alle
          verfügbaren Informationen über die Herkunft der Daten
        </li>
      </ul>
      <p>
        Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob personenbezogene Daten
        an ein Drittland oder an eine internationale Organisation übermittelt wurden. Sofern dies
        der Fall ist, so steht der betroffenen Person im übrigen das Recht zu, Auskunft über die
        geeigneten Garantien im Zusammenhang mit der übermittlung zu erhalten.
      </p>
      <p>
        Möchten Sie dieses Auskunftsrecht in Anspruch nehmen, können Sie sich hierzu jederzeit an
        unseren Datenschutzbeauftragten wenden.
      </p>
      <br />
      <p>
        <strong>Recht auf Berichtigung</strong>
      </p>
      <p>
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, die
        unverzügliche Berichtigung sie betreffender unrichtiger personenbezogener Daten zu
        verlangen. Ferner steht der betroffenen Person das Recht zu, unter Berücksichtigung der
        Zwecke der Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten -
        auch mittels einer ergänzenden Erklärung - zu verlangen.
      </p>
      <p>
        Möchten Sie dieses Berichtigungsrecht in Anspruch nehmen, können Sie sich hierzu jederzeit
        an unseren Datenschutzbeauftragten wenden.
      </p>
      <br />
      <p>
        <strong>Recht auf Löschung (Recht auf Vergessen werden)</strong>
      </p>
      <p>
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, von dem
        Verantwortlichen dieser Website zu verlangen, dass die sie betreffenden personenbezogenen
        Daten unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft und soweit
        die Verarbeitung nicht erforderlich ist:
      </p>
      <ul>
        <li>
          Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf sonstige Weise
          verarbeitet, für welche sie nicht mehr notwendig sind
        </li>
        <li>
          Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung stützte,
          und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung
        </li>
        <li>
          Die betroffene Person legt aus Gründen, die sich aus ihrer besonderen Situation ergeben,
          Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe
          für die Verarbeitung vor, oder die betroffene Person legt im Falle von Direktwerbung und
          damit verbundenem Profiling Widerspruch gegen die Verarbeitung ein
        </li>
        <li>Die personenbezogenen Daten wurden unrechtmässig verarbeitet</li>
        <li>
          Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung
          nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der
          Verantwortliche unterliegt
        </li>
        <li>
          Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
          Informationsgesellschaft, die einem Kind direkt gemacht wurden, erhoben
        </li>
      </ul>
      <p>
        Sofern einer der oben genannten Gründe zutrifft und Sie die Löschung von personenbezogenen
        Daten, die beimBetreiber dieser Website gespeichert sind, veranlassen möchten, können Sie
        sich hierzu jederzeit an unseren Datenschutzbeauftragten wenden. Der Datenschutzbeauftragte
        dieser Website wird veranlassen, dass dem Löschverlangen unverzüglich nachgekommen wird.
      </p>
      <br />
      <p>
        <strong>Recht auf Einschränkung der Verarbeitung</strong>
      </p>
      <p>
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, von dem
        Verantwortlichen dieser Website die Einschränkung der Verarbeitung zu verlangen, wenn eine
        der folgenden Voraussetzungen gegeben ist:
      </p>
      <ul>
        <li>
          Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person bestritten,
          und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der
          personenbezogenen Daten zu überprüfen
        </li>
        <li>
          Die Verarbeitung ist unrechtmässig, die betroffene Person lehnt die Löschung der
          personenbezogenen Daten ab und verlangt stattdessen die Einschränkung der Nutzung der
          personenbezogenen Daten
        </li>
        <li>
          Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der Verarbeitung
          nicht länger, die betroffene Person benötigt sie jedoch zur Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen
        </li>
        <li>
          Die betroffene Person hat aus Gründen, die sich aus ihrer besonderen Situation ergeben,
          Widerspruch gegen die Verarbeitung eingelegt und es steht noch nicht fest, ob die
          berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen Person überwiegen
        </li>
      </ul>
      <p>
        Sofern eine der oben genannten Voraussetzungen gegeben ist Sie die Einschränkung von
        personenbezogenen Daten, die beim Betreiber dieser Website gespeichert sind, verlangen
        möchten, können Sie sich hierzu jederzeit an unseren Datenschutzbeauftragten wenden. Der
        Datenschutzbeauftragte dieser Website wird die Einschränkung der Verarbeitung veranlassen.
      </p>
      <br />
      <p>
        <strong>Recht auf Datenübertragbarkeit</strong>
      </p>
      <p>
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, die sie
        betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren
        Format zu erhalten. Sie hat ausserdem das Recht, dass diese Daten bei Vorliegen der
        gesetzlichen Voraussetzungen einem anderen Verantwortlichen übermittelt werden.
      </p>
      <p>
        Ferner hat die betroffene Person das Recht, zu erwirken, dass die personenbezogenen Daten
        direkt von einem Verantwortlichen an einen anderen Verantwortlichen übermittelt werden,
        soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer
        Personen beeinträchtigt werden.
      </p>
      <p>
        Zur Geltendmachung des Rechts auf Datenübertragbarkeit können Sie sich jederzeit an den vom
        Betreiber dieser Website bestellten Datenschutzbeauftragten wenden.
      </p>
      <br />
      <p>
        <strong>Recht auf Widerspruch</strong>
      </p>
      <p>
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, aus
        Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung
        sie betreffender personenbezogener Daten, Widerspruch einzulegen.
      </p>
      <p>
        Der Betreiber dieser Website verarbeitet die personenbezogenen Daten im Falle des
        Widerspruchs nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die
        Verarbeitung nachweisen, die den Interessen, Rechten und Freiheiten der betroffenen Person
        überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen dient.
      </p>
      <p>
        Zur Ausübung des Rechts auf Widerspruch können Sie sich direkt an den
        Datenschutzbeauftragten dieser Website wenden.
      </p>
      <br />
      <p>
        <strong>Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</strong>
      </p>
      <p>
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, eine
        abgegebene Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen.
      </p>
      <p>
        Möchten Sie Ihr Recht auf Widerruf einer Einwilligung geltend machen, können Sie sich hierzu
        jederzeit an unseren Datenschutzbeauftragten wenden.
      </p>
      <p>&nbsp;</p>
      <h2>Kostenpflichtige Leistungen</h2>
      <p>
        Zur Erbringung kostenpflichtiger Leistungen werden von uns zusätzliche Daten erfragt, wie
        z.B. Zahlungsangaben, um Ihre Bestellung resp. Ihren Auftrag ausführen zu können. Wir
        speichern diese Daten in unseren Systemen, bis die gesetzlichen Aufbewahrungsfristen
        abgelaufen sind.
      </p>
      <p>&nbsp;</p>
      <h2>Datenschutzerklärung für Google Analytics</h2>
      <p>
        Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Ireland Limited.
        Wenn der Verantwortliche für die Datenverarbeitung auf dieser Website ausserhalb des
        Europäischen Wirtschaftsraumes oder der Schweiz sitzt, dann erfolgt die Google Analytics
        Datenverarbeitung durch Google LLC. Google LLC und Google Ireland Limited werden nachfolgend
        &laquo;Google&raquo; genannt.
      </p>
      <p>
        Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Nutzer
        interessanter ausgestalten. Diese Website verwendet Google Analytics zudem für eine
        geräteübergreifende Analyse von Besucherströmen, die über eine User-ID durchgeführt wird.
        Sofern Sie über ein Google-Benutzerkonto verfügen, können Sie in den dortigen Einstellungen
        unter &laquo;Meine Daten&raquo;, &laquo;persönliche Daten&raquo; die geräteübergreifende
        Analyse Ihrer Nutzung deaktivieren.
      </p>
      <p>
        Rechtsgrundlage für die Nutzung von Google Analytics ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO.
        Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
        anderen Daten von Google zusammengeführt. Wir weisen Sie darauf hin, dass auf dieser Website
        Google Analytics um den Code &laquo;_anonymizeIp();&raquo; erweitert wurde, um eine
        anonymisierte Erfassung von IP-Adressen zu gewährleisten. Dadurch werden IP-Adressen gekürzt
        weiterverarbeitet, eine Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit den
        über Sie erhobenen Daten ein Personenbezug zukommt, wird dieser also sofort ausgeschlossen
        und die personenbezogenen Daten damit umgehend gelöscht.
      </p>
      <p>
        Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA
        übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese
        Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
        Websitenaktivitäten zusammenzustellen und um weitere mit der Websitennutzung und der
        Internetnutzung verbundene Dienstleistungen gegenüber dem Websitenbetreiber zu erbringen.
        Für die Ausnahmefälle, in denen personenbezogene Daten in die USA übertragen werden, hat
        sich Google dem EU-US Privacy Shield unterworfen,{' '}
        <a
          href="https://www.privacyshield.gov/EU-US-Framework"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.privacyshield.gov/EU-US-Framework
        </a>
        .
      </p>
      <p>
        Google Analytics verwendet Cookies. Die durch den Cookie erzeugten Informationen über Ihre
        Benutzung dieser Website werden in der Regel an einen Server von Google in den USA
        übertragen und dort gespeichert. Sie können die Speicherung der Cookies durch eine
        entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf
        hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
        vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das
        Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse)
        an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter
        dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:{' '}
        <a
          href="https://www.swissanwalt.ch/de/gaoptout.aspx"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Analytics deaktivieren
        </a>
        .
      </p>
      <p>
        Ausserdem können Sie die Nutzung von Google Analytics auch verhindern, indem sie auf diesen
        Link klicken:{' '}
        <a
          href="https://tools.google.com/dlpage/gaoptout?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Analytics deaktivieren
        </a>
        . Hierdurch wird ein sog. opt-out Cookie auf ihrem Datenträger gespeichert, der die
        Verarbeitung personenbezogener Daten durch Google Analytics verhindert. Bitte beachten Sie,
        dass bei einem Löschen sämtlicher Cookies auf Ihrem Endgerät auch diese Opt-out-Cookies
        gelöscht werden, d.h., dass Sie erneut die Opt-out-Cookies setzen müssen, wenn Sie weiterhin
        diese Form der Datenerhebung verhindern wollen. Die Opt-out-Cookies sind pro Browser und
        Rechner/Endgerät gesetzt und müssen daher für jeden Browser, Rechner oder anderes Endgerät
        gesondert aktiviert werden.
      </p>
      <p>&nbsp;</p>
      <h2>Datenschutzerklärung für Instagram</h2>
      <p>
        Auf unserer Website sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen
        werden angeboten durch die Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA
        integriert. Wenn Sie in Ihrem Instagram-Account eingeloggt sind können Sie durch Anklicken
        des Instagram-Buttons die Inhalte unserer Seiten mit Ihrem Instagram-Profil verlinken.
        Dadurch kann Instagram den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen
        darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten
        Daten sowie deren Nutzung durch Instagram erhalten.
      </p>
      <p>
        Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Instagram:{' '}
        <a
          href="https://instagram.com/about/legal/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://instagram.com/about/legal/privacy/
        </a>
      </p>
      <p>&nbsp;</p>
      <h2>Urheberrechte</h2>
      <p>
        Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf
        der Website, gehören ausschliesslich dem Betreiber dieser Website oder den speziell
        genannten Rechteinhabern. Für die Reproduktion von sämtlichen Dateien, ist die schriftliche
        Zustimmung des Urheberrechtsträgers im Voraus einzuholen.
      </p>
      <p>
        Wer ohne Einwilligung des jeweiligen Rechteinhabers eine Urheberrechtsverletzung begeht,
        kann sich strafbar und allenfalls schadenersatzpflichtig machen.
      </p>
      <p>&nbsp;</p>
      <h2>Allgemeiner Haftungsausschluss</h2>
      <p>
        Alle Angaben unseres Internetangebotes wurden sorgfältig geprüft. Wir bemühen uns, unser
        Informationsangebot aktuell, inhaltlich richtig und vollständig anzubieten. Trotzdem kann
        das Auftreten von Fehlern nicht völlig ausgeschlossen werden, womit wir keine Garantie für
        Vollständigkeit, Richtigkeit und Aktualität von Informationen auch
        journalistisch-redaktioneller Art übernehmen können. Haftungsansprüche aus Schäden
        materieller oder ideeller Art, die durch die Nutzung der angebotenen Informationen
        verursacht wurden, sind ausgeschlossen, sofern kein nachweislich vorsätzliches oder grob
        fahrlässiges Verschulden vorliegt.
      </p>
      <p>
        Der Herausgeber kann nach eigenem Ermessen und ohne Ankündigung Texte verändern oder löschen
        und ist nicht verpflichtet, Inhalte dieser Website zu aktualisieren. Die Benutzung bzw. der
        Zugang zu dieser Website geschieht auf eigene Gefahr des Besuchers. Der Herausgeber, seine
        Auftraggeber oder Partner sind nicht verantwortlich für Schäden, wie direkte, indirekte,
        zufällige, vorab konkret zu bestimmende oder Folgeschäden, die angeblich durch den Besuch
        dieser Website entstanden sind und übernehmen hierfür folglich keine Haftung.
      </p>
      <p>
        Der Herausgeber übernimmt ebenfalls keine Verantwortung und Haftung für die Inhalte und die
        Verfügbarkeit von Website Dritter, die über externe Links dieser Website erreichbar sind.
        Für den Inhalt der verlinkten Seiten sind ausschliesslich deren Betreiber verantwortlich.
        Der Herausgeber distanziert sich damit ausdrücklich von allen Inhalten Dritter, die
        möglicherweise straf- oder haftungsrechtlich relevant sind oder gegen die guten Sitten
        verstossen.
      </p>
      <p>&nbsp;</p>
      <h2>Änderungen</h2>
      <p>
        Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. Es gilt die
        jeweils aktuelle, auf unserer Website publizierte Fassung. Soweit die Datenschutzerklärung
        Teil einer Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer Aktualisierung über die
        änderung per E-Mail oder auf andere geeignete Weise informieren.
      </p>
      <p>&nbsp;</p>
      <h2>Fragen an den Datenschutzbeauftragten</h2>
      <p>
        Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie
        sich direkt an die für den Datenschutz zu Beginn der Datenschutzerklärung aufgeführten,
        verantwortlichen Person in unserer Organisation.
      </p>
      <p>&nbsp;</p>
      <p>
        Allschwil, 01.01.2021
        <br />
        Quelle:{' '}
        <a href="https://www.swissanwalt.ch" target="_blank" rel="noopener noreferrer">
          SwissAnwalt
        </a>
      </p>
    </Layout>
  );
};

export default Index;
